<template>
	<div 
  class="TarifarCont">
    <v-btn 
    @click="setModalRate(typeRate)"
    text rounded class="btn">
      Tarifar
      <div class="iconCont">
	      <v-icon> 
	      	mdi-content-copy 
	      </v-icon>
    	</div>
    </v-btn>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default{
	name:'ButtonActiveModalRate',
  computed:{
    typeRate(){
      if(this.$router.currentRoute.path=='/subscription/quotation/proportional')
      {
        return 'proportional'
      }
      else{
        return 'non proportional'
      }
    }
  },
  methods:{
    ...mapMutations(["setModalRate"]),
  }
}
</script>
<style lang="less" scoped>
		//BOTON TARIFAR
    .TarifarCont {
      width: 150px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {
        text-transform: none;
        letter-spacing: normal;
        font-weight: 400;
        color: #547fa9;
      }

      .iconCont {
        width: 27px;
        height: 27px;
        border-radius: 27px;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #a8bfd9;
        i {
          font-size: 16px;
          margin-left: 2px;
          color: #003d6d;
        }
      }
    }
</style>