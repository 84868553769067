<template>
	<div class="text-center">
    <v-menu 
    	z-index="3000"
    	offset-x left
    	:close-on-content-click="false"
    	>
      <template 
      	v-slot:activator="{ on, attrs }"
      	>
			    <div 
				    class="
				    cont 
				    d-flex 
				    justify-center 
				    align-center
				    ">
						<!--
							CONTADOR DE NOTIFICACIONES
						-->
						<div 
						v-if="NumberNotifications>0"
						class="Counter">
							<span>
								{{NumberNotifications}}
							</span>
						</div>

						<v-btn 
							v-bind="attrs"
          		v-on="on" 
							icon
							>
							<v-icon>
								mdi-magnify
							</v-icon>
						</v-btn>
					</div>
      </template>

      <!--LISTA DE NOTIFICACIONES-->
      <div class="SearchBackground">
	      <div class="searchContMenu">
	        <div class="InputCont">
	        	<div class="InputIcon">
	        		<v-icon>
	        			mdi-magnify
	        		</v-icon>
	        	</div>
	        	<div class="InputCnt">
	        		<input 
	        			class="input"
	        			placeholder="Search" 
	        		>
	        	</div>
	        </div>

	        <div class="InputCont">
	        	<div class="InputIcon">
	        		<v-icon>
	        			mdi-filter-outline
	        		</v-icon>
	        	</div>
	        	<div class="InputCnt">
	        		<input 
	        			class="input"
	        			placeholder="Search" 
	        		>
	        	</div>
	        </div>

	        <div class="FiltersCont">
	        	<v-chip
	        		v-if="chip1"
						  @click:close="chip1 = false"

	        		class="chip"
						  close label
						  close-icon="mdi-close-circle"
						  color="#EDF2F8"
						>
							Data range
						</v-chip>

						<v-chip
							v-if="chip2"
						  @click:close="chip2 = false"

	        		class="chip"
						  close label
						  close-icon="mdi-close-circle"
						  color="#EDF2F8"
						>
							Type
						</v-chip>

						<v-chip
							v-if="chip3"
						  @click:close="chip3 = false"

	        		class="chip"
						  close label
						  close-icon="mdi-close-circle"
						  color="#EDF2F8"
						>
							Subtype
						</v-chip>
	        </div>
	      </div>
    	</div>
    </v-menu>
  </div>
</template>
<script>
export default{
	data(){
		return{
			NumberNotifications:0,
			chip1:true,
			chip2:true,
			chip3:true,
		}
	}
}
</script>
<style lang="less">
@import "~@/assets/style/MenuActions.less";
.SearchBackground{
	.searchContMenu{
		width: 490px !important;
		height: 200px;
		background: white;
		padding: 15px;
		display: flex;
		justify-content: center;
		align-content: space-between;
		flex-wrap: wrap;

		.InputCont{
			width: 100%;
			height: 30%;
			background:#EDF2F8;
			border-radius: 10px;
			display: flex;
			justify-content: flex-start;
			align-items:center;

			.InputIcon{
				width: 11%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				i{
					color: #003D6D;
				}
			}

			.InputCnt{
				width: 89%;
				height: 100%;
				
				.input{
					width: 100%;
					height: 100%;
				}
				.input:focus{
					outline: none;
				}
			}
		}

		.FiltersCont{
			width: 100%;
			height: 27%;

			.chip{
				height: 40px;
				margin-right: 10px;
			}
		}
	}
}

/*
 mofica estilos de vuetify
*/
.v-menu__content{
  position: fixed !important;
}
.theme--light.v-chip:not(.v-chip--active){
		background: #EDF2F8 !important;
		border-radius: 2px;
	}
	.v-chip__content{
		color: #547FA9 !important;
	}
	.v-chip__close{
		color: #F59607 !important
	}


@media(max-width: 650px){
	.SearchBackground{
		width: 100%;
    height: 100vh;
    left: 0; top: 0;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    overflow: auto !important;
    padding-top: 10%;
    &::before{
    	content: "";
    	width: 100%;
    	height: 100%;
    	position:absolute;
    	left: 0;top: 0;
    	z-index: 0;
    	background:rgba(0,0,0,0.5);
    }
		
		.searchContMenu{
			width: 83%  !important;
			border-radius:15px;
			z-index: 1000;
		}

	}


/*
	Estilos de vuetify para el modal
*/
	.v-menu__content{
		left: 0 !important; 
		top: 0 !important;
		max-width: 100% !important;
	}
}
</style>