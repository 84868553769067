<template>
<div class="Cont d-flex justify-start align-center flex-wrap align-content-center mt-6">
	<!--TITULO-->
	<div class="TitleCont">
		<h5>
			Summary
		</h5>
	</div>
	<div class="Table">
		<div class="Line">
			<div class="Label">
				Total Rate
			</div>
			<div class="Ammount">
				$ 2,621
			</div>
		</div>
		<div class="Line">
			<div class="Label">
				Premium
			</div>
			<div class="Ammount">
				$ 262,061,26
			</div>
		</div>
	</div>
</div>
</template>
<script>
export default{
	name:'Summary',
	data(){
		return{
		}
	}
}
</script>
<style lang="less" scoped>
/*
Estilos compartidos por los componentes
del tarifador, controlan:
	- Clase .flex()
	- Titulo general
	- Contenedor general
*/
@import '~@/assets/style/Subscription/Rate.less';
.Table{
	.flex();
	width: 100%;
	height: auto;
	align-items: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;

	.Line{
		.flex();
		width:100%;
		height: 50px;
		justify-content: flex-start;

		.Label{
			font-weight: 600;
			color: #547FA9;
		}
		.Ammount{
			.flex();
			width: 150px;
			height: 100%;
			justify-content: flex-end;
			text-align: right;
		}
	}
}
</style>