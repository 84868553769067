<template>
  <div class="BarNavGeneralContent">
    <div class="BarNavGeneralCont d-flex">
      <!--LINKS-->
      <router-link to="/subscription/" class="BarNavLinkApp d-flex justify-ceter align-center" :class="link1">
        Registered <br class="ShowOnMovil" />
        Accounts
      </router-link>

      <router-link to="/subscription/submission" class="BarNavLinkApp ml-5 d-flex justify-ceter align-center" :class="link2">
        Subscription
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SubscriptionBarNav',
  props: {
    //DEFINE QUE ESTILOS AÑADIMOS A LOS LINKS
    link1: String,
    link2: String,
  },
};
</script>
<style lang="less" scoped>
@import '~@/assets/style/BarNav.less';
</style>
