var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    DeductionsCont\n    d-flex\n    justify-start\n    align-center\n    flex-wrap\n    align-content-center\n  "},[_c('div',{staticClass:"TitleCont d-flex justify-space-between align-center"},[_c('h5',[_vm._v("Deductions")]),(_vm.ShowRateBtn == '/subscription/quotation/non-proportional')?_c('div',{staticClass:"TarifarBtn"},[_c('ButtonActiveModalRate')],1):_vm._e()]),_c('v-form',{staticClass:"form d-flex flex-wrap"},[_c('div',{staticClass:"InputContent"},[_c('v-text-field',{attrs:{"label":"Brokerage","type":"number","suffix":"%","min":"0","max":"100","error-messages":_vm.quotationValids('deductions', 'brokerage')},on:{"blur":function($event){_vm.setStateDeductions('brokerage', this);
          _vm.checkDeductionsColumn('brokerage');
          _vm.sumDeductions();}},model:{value:(_vm.deductions.brokerage),callback:function ($$v) {_vm.$set(_vm.deductions, "brokerage", $$v)},expression:"deductions.brokerage"}})],1),_c('div',{staticClass:"InputContent"},[_c('v-text-field',{attrs:{"label":"Taxes","type":"number","suffix":"%","min":"0","max":"100","error-messages":_vm.quotationValids('deductions', 'taxes')},on:{"blur":function($event){_vm.setStateDeductions('taxes', this);
          _vm.checkDeductionsColumn('taxes');
          _vm.sumDeductions();}},model:{value:(_vm.deductions.taxes),callback:function ($$v) {_vm.$set(_vm.deductions, "taxes", $$v)},expression:"deductions.taxes"}})],1),_c('div',{staticClass:"InputContent"},[_c('v-text-field',{attrs:{"label":"Eng fee","type":"number","min":"0","max":"100","suffix":"%","error-messages":_vm.quotationValids('deductions', 'eng')},on:{"blur":function($event){_vm.setStateDeductions('eng', this);
          _vm.checkDeductionsColumn('eng');
          _vm.sumDeductions();}},model:{value:(_vm.deductions.eng),callback:function ($$v) {_vm.$set(_vm.deductions, "eng", $$v)},expression:"deductions.eng"}})],1),_c('div',{staticClass:"InputContent"},[_c('v-select',{attrs:{"required":"","label":"Type","items":_vm.type,"item-text":"type","item-value":"type","min":"0","max":"100","disabled":_vm.type.length === 0},on:{"input":function($event){_vm.setStateDeductions('deductionType', this);
          _vm.checkDeductionsColumn('deductionType');}},model:{value:(_vm.deductions.deductionType),callback:function ($$v) {_vm.$set(_vm.deductions, "deductionType", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"deductions.deductionType"}})],1),_c('div',{staticClass:"InputContent"},[_c('v-text-field',{attrs:{"label":"Fronting fee","type":"number","suffix":"%","min":"0","max":"100","error-messages":_vm.quotationValids('deductions', 'fronting')},on:{"blur":function($event){_vm.setStateDeductions('fronting', this);
          _vm.checkDeductionsColumn('fronting');
          _vm.sumDeductions();}},model:{value:(_vm.deductions.fronting),callback:function ($$v) {_vm.$set(_vm.deductions, "fronting", $$v)},expression:"deductions.fronting"}})],1),_c('div',{staticClass:"InputContentLarge"},[_c('v-text-field',{attrs:{"label":"Premium reserve only Colombia","type":"number","min":"0","max":"100","suffix":"%","error-messages":_vm.quotationValids('deductions', 'premiumReserve')},on:{"blur":function($event){_vm.setStateDeductions('premiumReserve', this);
          _vm.checkDeductionsColumn('premiumReserve');}},model:{value:(_vm.deductions.premiumReserve),callback:function ($$v) {_vm.$set(_vm.deductions, "premiumReserve", $$v)},expression:"deductions.premiumReserve"}})],1),_c('div',{staticClass:"InputContent"},[_c('v-text-field',{attrs:{"label":"LTA","type":"number","suffix":"%","min":"0","max":"100","error-messages":_vm.quotationValids('deductions', 'lta')},on:{"blur":function($event){_vm.setStateDeductions('lta', this);
          _vm.checkDeductionsColumn('lta');
          _vm.sumDeductions();}},model:{value:(_vm.deductions.lta),callback:function ($$v) {_vm.$set(_vm.deductions, "lta", $$v)},expression:"deductions.lta"}})],1),_c('div',{staticClass:"InputContent"},[_c('v-text-field',{attrs:{"label":"Others","type":"number","suffix":"%","min":"0","max":"100","error-messages":_vm.quotationValids('deductions', 'others')},on:{"blur":function($event){_vm.setStateDeductions('others', this);
          _vm.checkDeductionsColumn('others');
          _vm.sumDeductions();}},model:{value:(_vm.deductions.others),callback:function ($$v) {_vm.$set(_vm.deductions, "others", $$v)},expression:"deductions.others"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }