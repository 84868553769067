<template>
  <div
    class="CopyAccount HideOnMovil d-flex justify-space-between align-center"
  >
    <v-menu left z-index="3000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="btn" text rounded>
          <div class="ButtonText">Copy Account</div>

          <div class="icon">
            <v-icon> mdi-content-copy </v-icon>
          </div>
        </v-btn>
      </template>

      <div>
        <v-list>
          <div class="">
            <v-btn
              v-for="(item, index) in CopyOptions"
              :key="index"
              text
              class="button d-flex justify-start align-center"
							@click="createCopyAccount()"
            >
              {{ item.content }}
            </v-btn>
          </div>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'CopyAccount',
  data() {
    return {
      CopyOptions: [
        {
          content: 'New Account',
        },
      ],
    };
  },
	methods: {
		...mapActions(['createCopyAccount']),
	},
};
</script>
<style lang="less" scoped>
.CopyAccount {
  width: auto;
  height: 100%;
  position: absolute;
  right: 0;
  .btn {
    font-weight: normal !important;
    font-size: 16px;
    color: #547fa9 !important;
    font-weight: 400 !important;
    letter-spacing: normal !important;
    text-transform: none !important;
    cursor: pointer;
    display: inline-flex;
    .ButtonText {
      color: inherit;
    }
    .icon {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      background: #a8bfd9;
      margin-left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 14px;
        color: #003d6d;
      }
    }
  }
}
.button {
  height: 45px;
  color: #003d6d;
  cursor: pointer;
  width: 100% !important;
  text-transform: none;
  letter-spacing: initial;
}

@media (max-width: 640px) {
  .CopyAccount {
    height: 40px;
    .btn {
      width: 30px !important;
      height: 30px;

      .ButtonText {
        display: none;
      }
      .icon {
        margin-left: 0px;
      }
    }
  }

  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 30px !important;
  }
}
</style>