<template>
  <div>
    <h5 class="mt-1">CC Participants</h5>
    <div class="OptionsCont">
      <v-autocomplete
        :items="ccParticipants"
        v-model.trim="$v.ccValue.$model"
        @change="$v.ccValue.$touch()"
        @input="$v.ccValue.$touch()"
        @blur="$v.ccValue.$touch()"
        solo
        flat
        label="Choose CC participants"
        color="white"
        multiple
        chips
        deletable-chips
      ></v-autocomplete>
    </div>
  </div>
</template>

<script>
import { formValidations } from "@/mixins/formValidations";
/* vuelidate mixin & validators */
import { validationMixin } from 'vuelidate'
import { helpers } from 'vuelidate/lib/validators'

const isOptional = (value) => !helpers.req(value) || value.indexOf('cool') >= 0

export default {
  name: 'participants',
  mixins: [validationMixin, formValidations],
  props: {
    ccParticipants: {
      type: Array,
    },
  },
  validations: {
    ccValue: {
			isOptional
    },
  },
}
</script>

<style lang="less">
h5 {
  font-weight: normal;
  font-size: 15px;
  color: #547fa9;
}

.OptionsCont {
  width: 100%;
  height: 45px;
}
.theme--light.v-chip:not(.v-chip--active) {
  background: #edf2f8 !important;
  border-radius: 2px;
}
.v-chip__content {
  color: #003d6d !important;
}
.v-chip__close {
  color: #f59607 !important;
}
@media (max-width: 640px) {
  h5 {
    font-size: 14px;
  }
}
</style>
