<template>
	<div>
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
			    <div 
				    class="
				    cont 
				    d-flex 
				    justify-center 
				    align-center
				    ">
						<!--
							CONTADOR DE NOTIFICACIONES
						-->
						<div 
						v-if="NumberNotifications>0"
						class="Counter">
							<span>
								{{NumberNotifications}}
							</span>
						</div>

						<v-btn 
							v-bind="attrs"
          		v-on="on" 
							icon
							>
							<v-icon>
								mdi-bell-outline
							</v-icon>
						</v-btn>
					</div>
      </template>

      <!--LISTA DE NOTIFICACIONES-->
      <v-list class="ListNotifications">
        <v-list-item
          v-for="(item, index) in notifications"
          :key="index"
        >
          <v-list-item-title>
          	{{ item.title }}
        	</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default{
	data(){
		return{
			NumberNotifications:0,
			notifications: [
        { title: 'Notif' },
        { title: 'Notif' },
        { title: 'Notif' },
        { title: 'Notif' },
      ],
		}
	}
}
</script>
<style scoped lang="less">
@import "~@/assets/style/MenuActions.less";
.ListNotifications{
	width: 150px !important;
}
</style>