<template>
  <div class="ExchangeCalcCont d-flex justify-center flex-wrap align-center">
    <!--TITULO-->
    <div class="TitleComponent d-flex justify-start align-center">
      <h5>Total insurable value</h5>
    </div>

    <div class="ExchangeCont">
      <div class="TableCont">
        <!--COLUMNA IZQUIERDA-->
        <div class="LargeColumn">
          <!--TITULO-->
          <div class="titleCont">
            <div class="title">Original Currency</div>
          </div>

          <!--INPUTS-->
          <div class="InputLine">
            <!--LABEL-->
            <div class="inputLabel">Property Damage</div>
            <!--INPUT-->
            <div class="inputCont">
              <!-- <v-text-field v-model="tiv.propertyDamage" type="number" prefix="$" @blur="calculeUSD()" text /> -->
              <currency-input
                v-model="tiv.propertyDamage"
                :options="currencyOptions"
                @blur="
                  calculeUSD();
                  setStateTIV('propertyDamage', this);
                  checkInsurableColumn('propertyDamage');
                "
                :suffix="quotation.currency"
              />
            </div>
          </div>
          <div class="InputLine">
            <!--LABEL-->
            <div class="inputLabel">Business Interruption</div>
            <!--INPUT-->
            <div class="inputCont">
              <!-- <v-text-field v-model="tiv.businessInterruption" type="number" prefix="$" @blur="calculeUSD()" text /> -->
              <currency-input
                v-model="tiv.businessInterruption"
                :options="currencyOptions"
                @blur="
                  calculeUSD();
                  setStateTIV('businessInterruption', this);
                  checkInsurableColumn('businessInterruption');
                "
                :suffix="quotation.currency"
              />
            </div>
          </div>
          <div class="Stocks">
            <!--LABEL-->
            <div class="inputLabel">Stocks</div>
            <!--INPUT-->
            <div class="inputCont">
              <!-- <v-text-field v-model="tiv.stock" type="number" prefix="$" @blur="calculeUSD()" text /> -->
              <currency-input
                v-model="tiv.stock"
                :options="currencyOptions"
                @blur="
                  calculeUSD();
                  setStateTIV('stock', this);
                  checkInsurableColumn('stock');
                "
                :suffix="quotation.currency"
              />
            </div>
            <div class="Percentaje">
              <v-text-field
                v-model="tiv.porcentaje"
                @blur="
                  setStateTIV('porcentaje', this);
                  checkInsurableColumn('porcentaje');
                "
                type="number"
                suffix="%"
                :error-messages="quotationValids('tiv', 'porcentaje')"
              />
            </div>
          </div>

          <div class="Porcentaje ShowFlexOnMovil">
            <div class="PorcentajeMovil">
              <v-text-field
                v-model="tiv.porcentaje"
                @blur="
                  setStateTIV('porcentaje', this);
                  checkInsurableColumn('porcentaje');
                "
                type="number"
                suffix="%"
                :error-messages="quotationValids('tiv', 'porcentaje')"
              />
            </div>
          </div>

          <!--TOTAL-->
          <div class="Total">
            <div class="TotalLabel">Total</div>
            <div class="TotalData">{{ tiv.total }}</div>
          </div>
        </div>

        <div class="Middle HideOnMovil">
          <div class="MiddleCont" />
        </div>
        <!--COLUMNA DERECHA-->
        <div class="NormalColumn">
          <!--TITULO-->
          <div class="titleCont">USD</div>

          <!--INPUTS-->
          <div class="InputLine">
            <!--LABEL-->
            <div class="inputLabel ShowFlexOnMovil">Property Demage</div>
            <!--INPUT-->
            <div class="inputCont">
              <!-- <v-text-field v-model="tiv.propertyDamageUSD" type="number" prefix="$" @blur="calculeUSD()" text /> -->
              <currency-input
                :value="tiv.propertyDamageUSD"
                :options="currencyOptions"
                @blur="
                  calculeUSD();
                  setStateTIV('propertyDamageUSD', this);
                "
                suffix="USD"
                @change="
                  value = $event;
                  setStateTIV('propertyDamageUSD', $event);
                  checkInsurableColumn('propertyDamageUSD');
                "
              />
            </div>
          </div>
          <div class="InputLine">
            <!--LABEL-->
            <div class="inputLabel ShowFlexOnMovil">Business interruption</div>
            <!--INPUT-->
            <div class="inputCont">
              <!-- <v-text-field v-model="tiv.businessInterruptionUSD" type="number" prefix="$" @blur="calculeUSD()" text /> -->
              <currency-input
                :value="tiv.businessInterruptionUSD"
                :options="currencyOptions"
                @blur="
                  calculeUSD();
                  setStateTIV('businessInterruptionUSD', this);
                "
                suffix="USD"
                @change="
                  value = $event;
                  setStateTIV('businessInterruptionUSD', $event);
                  checkInsurableColumn('businessInterruptionUSD');
                "
              />
            </div>
          </div>
          <div class="InputLine">
            <!--LABEL-->
            <div class="inputLabel ShowFlexOnMovil">Stocks</div>

            <!--INPUT-->
            <div class="inputCont">
              <!-- <v-text-field v-model="tiv.stockUSD" type="number" prefix="$" @blur="calculeUSD()" text /> -->
              <currency-input
                :value="tiv.stockUSD"
                :options="currencyOptions"
                @blur="
                  calculeUSD();
                  setStateTIV('stockUSD', this);
                "
                suffix="USD"
                @change="
                  value = $event;
                  setStateTIV('stockUSD', $event);
                  checkInsurableColumn('stockUSD');
                "
              />
            </div>
          </div>

          <!--TOTAL-->
          <div class="Total" />
        </div>
      </div>
    </div>

    <div class="ExpansionLineTop mt-3 HideOnMovil"></div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
/* validations */
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { DigitsAndDecimals, Percentage } from '@/constants/validations';
import { formValidations } from '@/mixins/formValidations';
/* components */
import CurrencyInput from '@/components/CurrencyInput/CurrencyInput.vue';

export default {
  name: 'ExchangeCalculator',
  components: {
    CurrencyInput,
  },
  mixins: [formValidations, validationMixin],
  data() {
    return {
      currencyOptions: {
        currency: 'MXN',
        currencyDisplay: 'narrowSymbol',
        locale: 'en-US',
      },
    };
  },
  computed: {
    ...mapGetters(['quotation', 'tiv']),
  },
  watch: {
    '$store.state.quotation.exchangeRate': function() {
      this.calculeUSD();
    },
    'tiv.propertyDamageUSD': async function(newVal) {
      this.setStateTIV('propertyDamageUSD', newVal);
      await this.checkInsurableColumn('propertyDamageUSD');
    },
    'tiv.businessInterruptionUSD': async function(newVal) {
      this.setStateTIV('businessInterruptionUSD', newVal);
      await this.checkInsurableColumn('businessInterruptionUSD');
    },
    'tiv.stockUSD': async function(newVal) {
      this.setStateTIV('stockUSD', newVal);
      await this.checkInsurableColumn('stockUSD');
    },
    'tiv.total': async function(newVal) {
      this.setStateTIV('total', newVal);
      await this.checkInsurableColumn('total');
    },
    'tiv.totalUSD': async function(newVal) {
      this.setStateTIV('totalUSD', newVal);
      await this.checkInsurableColumn('totalUSD');
    },
  },

  methods: {
    ...mapMutations(['setStateTIV']),
    ...mapActions(['saveQuotationColumn']),
    calculeUSD() {
      this.tiv.propertyDamageUSD = parseFloat(this.tiv.propertyDamage) / parseFloat(this.quotation.exchangeRate);
      this.tiv.businessInterruptionUSD = parseFloat(this.tiv.businessInterruption) / parseFloat(this.quotation.exchangeRate);
      this.tiv.stockUSD = parseFloat(this.tiv.businessInterruption) / parseFloat(this.quotation.exchangeRate);
      this.sumData();
    },
    sumData() {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      this.tiv.total =
        (!this.tiv.propertyDamage ? 0 : parseFloat(this.tiv.propertyDamage)) +
        (!this.tiv.businessInterruption ? 0 : parseFloat(this.tiv.businessInterruption)) +
        (!this.tiv.stock ? 0 : parseFloat(this.tiv.stock));

      this.tiv.total = formatter.format(this.tiv.total);

      this.tiv.totalUSD =
        (!this.tiv.propertyDamageUSD ? 0 : parseFloat(this.tiv.propertyDamageUSD)) +
        (!this.tiv.businessInterruptionUSD ? 0 : parseFloat(this.tiv.businessInterruptionUSD)) +
        (!this.tiv.stockUSD ? 0 : parseFloat(this.tiv.stockUSD));

      this.tiv.totalUSD = formatter.format(this.tiv.totalUSD);
    },
    async checkInsurableColumn(column) {
      this.$v.tiv[column].$touch();
      console.log(this.$v.tiv[column].$invalid, this.$v.tiv[column].$error);
      if (this.$v.tiv[column].$invalid) return;
      await this.saveQuotationColumn({
        table: 'insurable',
        parent: 'tiv',
        column,
      });
    },
  },
  validations: {
    tiv: {
      propertyDamage: { required, DigitsAndDecimals },
      businessInterruption: { required, DigitsAndDecimals },
      stock: { required, DigitsAndDecimals },
      total: { required, DigitsAndDecimals },
      totalUSD: { required, DigitsAndDecimals },
      porcentaje: { required, DigitsAndDecimals, Percentage },
      propertyDamageUSD: { required, DigitsAndDecimals },
      businessInterruptionUSD: { required, DigitsAndDecimals },
      stockUSD: { required, DigitsAndDecimals },
    },
  },
};
</script>
<style lang="less" scoped>
@inputLarge: 220px;
@InputHeightMovil: 70px;
.FlexCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ExchangeCalcCont {
  width: 100%;
  height: auto;

  //TITULO DEL COMPONENTE
  .TitleComponent {
    width: 100%;
    height: 50px;
    h5 {
      font-size: 20px;
      font-weight: 600;
      text-transform: capitalize;
      color: black;
    }
  }

  .ExchangeCont {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;

    .TableCont {
      .FlexCenter();
      width: 70%;
      height: auto;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      //COLUMNA DERECHA
      .LargeColumn {
        width: 55%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        align-content: space-between;
        flex-wrap: wrap;

        //TITULO
        .titleCont {
          width: 100%;
          height: 60px;
          display: flex;
          justify-content: flex-end;

          .title {
            .FlexCenter();
            width: @inputLarge;
            height: 100%;
            color: black;
            font-size: 20px;
            font-weight: 600;
          }
        }

        //INPUTS IZQUIERDA
        .InputLine {
          .FlexCenter();
          width: 100%;
          height: 55px;

          //LABEL
          .inputLabel {
            .FlexCenter();
            justify-content: flex-end;
            width: calc(100% - @inputLarge);
            height: 100%;
            color: #547fa9;
            padding-right: 7px;
            font-size: 16px;
          }

          //INPUT
          .inputCont {
            .FlexCenter();
            width: @inputLarge;
            height: 100%;
            overflow: hidden;
          }
        }

        //Stocks
        .Stocks {
          .FlexCenter();
          width: 100%;
          height: 55px;
          //LABEL
          .inputLabel {
            .FlexCenter();
            justify-content: flex-end;
            width: calc(100% - @inputLarge);
            height: 100%;
            color: #547fa9;
            padding-right: 7px;
            font-size: 16px;
          }

          //INPUT
          .inputCont {
            .FlexCenter();
            width: calc(@inputLarge - 60px);
            height: 100%;
            overflow: hidden;
          }

          //PORCENTAJE
          .Percentaje {
            .FlexCenter();
            width: 50px;
            height: 100%;
            overflow: hidden;
            margin-left: 10px;
          }
        }

        .Total {
          .FlexCenter();
          width: 100%;
          height: 70px;
          border-top: solid 1px #547fa9;
          justify-content: flex-end;
          font-weight: 600;

          .TotalLabel {
            .FlexCenter();
            justify-content: flex-end;
            width: calc(100% - @inputLarge);
            height: 100%;
            padding-right: 7px;
            color: #547fa9;
          }
          .TotalData {
            .FlexCenter();
            width: @inputLarge;
            height: 100%;
            font-weight: 600;
          }
        }

        .Porcentaje {
          justify-content: flex-end;
          width: 100%;
          height: 70px;
          .PorcentajeMovil {
            .FlexCenter();
            width: 100px;
            height: 100%;
          }
        }
      }

      .Middle {
        width: 15%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        align-content: flex-end;

        .MiddleCont {
          .FlexCenter();
          width: 100%;
          height: 70px;
          border-top: solid 1px #547fa9;
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      //COLUMNA IZQUIERDA
      .NormalColumn {
        width: @inputLarge;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;

        //TITULO
        .titleCont {
          .FlexCenter();
          width: 100%;
          height: 60px;
          color: black;
          font-size: 20px;
          font-weight: 600;
        }

        //INPUTS DERECHA
        .InputLine {
          .FlexCenter();
          width: 100%;
          height: 55px;
          .inputCont {
            .FlexCenter();
            width: 100%;
            height: 50px;
          }
        }

        //TOTAL
        .Total {
          .FlexCenter();
          width: 100%;
          height: 70px;
          border-top: solid 1px #547fa9;
          font-weight: bold;

          .TotalLabel {
            color: #547fa9;
            justify-content: flex-end;
            align-items: center;
            width: 30%;
            height: 100%;
            padding-right: 7px;
          }
          .TotalData {
            .FlexCenter();
            width: @inputLarge;
            height: 100%;
          }
        }
      }
    }
  }
}

.ExpansionLineTop {
  background: #a8bfd9 !important;
}
@media (max-width: 640px) {
  .ExchangeCalcCont {
    .ExchangeCont {
      .TableCont {
        width: 100%;
        height: auto;

        .LargeColumn {
          width: 100%;
          height: auto;

          .titleCont {
            justify-content: flex-start;
          }

          .InputLine,
          .Stocks {
            height: @InputHeightMovil;

            .inputLabel {
              width: 30%;
              text-align: right;
              justify-content: flex-end;
            }
            .inputCont {
              width: 70% !important;
            }
          }

          .Total {
            .TotalLabel {
              width: 30%;
            }
            .TotalData {
              width: 70%;
            }
          }
        }
        .NormalColumn {
          width: 100%;

          .InputLine {
            height: @InputHeightMovil;

            .inputLabel {
              justify-content: flex-end;
              width: 30%;
              text-align: right;
              padding-right: 7px;
              color: #547fa9;
            }
            .inputCont {
              width: 70% !important;
            }
          }

          .Total {
            width: 100%;
            height: 50px;

            .TotalData {
              width: 70%;
            }
          }
        }
      }
    }
  }
}
</style>
