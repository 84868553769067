<template>
  <v-expansion-panels class="ExpansionComponent ExpansionBordered mt-1">
    <v-expansion-panel>
      <!--TITULO DEL ACORDEON-->
      <v-expansion-panel-header
        @click="changeStateExpansive()"
        class="ExpansionTitle"
        expand-icon="mdi-plus-circle-outline"
      >
        Quotation
        <div class="ExpansionState HideOnMovil">
          {{ stateExpansiveMessage }}
        </div>
        <template v-slot:actions>
          <v-icon class="iconExpand">
            {{ iconExpansive }}
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <!--CUERPO-->
      <v-expansion-panel-content>
        <div class="ExpandContent">
          <Proportional />
          <Deductions />
          <InsurableValue />
          <Premium />
          <ToBe ref="toBe" />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Proportional from '@/components/subscription/quotation/Proportional';
import InsurableValue from '@/components/subscription/quotation/InsurableValue';
import Premium from '@/components/subscription/quotation/Premium';
import ToBe from '@/components/subscription/quotation/ToBe';
import Deductions from '@/components/subscription/quotation/Deductions';

//MIXINS
import { stateExpansiveManager } from '@/mixins/subscription.js';
export default {
  name: 'QuoProp',
  mixins: [stateExpansiveManager],
  components: {
    Proportional,
    InsurableValue,
    Deductions,
    Premium,    
    ToBe,
  },
};
</script>
<style lang="less" scoped>
//CONTENEDOR DE QUOTATION
@import '~@/assets/style/AccordionStyle.less';
</style>
