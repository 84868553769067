<template>
<div class="Cont d-flex justify-start align-center flex-wrap align-content-center mt-6 ">
	<!--TITULO-->
	<div class="TitleCont">
		<h5>
			Non CAT Rates
		</h5>
	</div>
	<div class="
		Table
		d-flex
		justify-end
		align-content-start
		flex-wrap
		">
		<!--CABECERA DE LA TABLA-->
		<div class="Line">
			<div class="Row Bold">
				Activity
			</div>
			<div class="Row Bold"/>
			<div class="Row Bold">
				Rate
			</div>
			<div class="Row Bold">
				Credit / Surchage
			</div>
			<div class="Row Bold">
				Reason
			</div>
			<div class="Row Bold">
				Final Rate
			</div>
			<div class="Row Bold">
				Premium
			</div>
		</div>

		<div class="Line">
			<div class="Row">
				<v-text-field
					v-model="FloodRate"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="FloodRate"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="FloodRate"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="FloodRate"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="FloodRate"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="FloodRate"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="FloodRate"
					>
				</v-text-field>
			</div>
		</div>

		<!--BOTON AÑADIR-->
		<div class="Line ButtonLine">
			<v-btn 
				class="btn" rounded text
				color="#003D6D"
				>
				<v-icon class="mr-2">
					mdi-plus-circle
				</v-icon>
				Add New
			</v-btn>
		</div>

		<!--SEPARADOR-->
		<div class="ExpansionLineTop mt-3 HideOnMovil"/>

	</div>
</div>
</template>
<script>
export default{
	name:'NonCat',
	data(){
		return{
			FloodRate:null
		}
	}
}
</script>
<style lang="less" scoped>
/*
Estilos compartidos por los componentes
del tarifador, controlan:
	- Clase .flex()
	- Titulo general
	- Contenedor general
*/
@import '~@/assets/style/Subscription/Rate.less';
	/*
		Estilos únicos del componente
	*/
.Table{
	.flex();
	width: 100%;
	height: auto;
	align-items: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;

	.Line{
		.flex();
		width:100%;
		height: 50px;
		justify-content: space-between;

		.Row{
			.flex();
			width: 13.7%;
			height: 50px;
			justify-content: flex-start;
		}

		.Bold{font-weight: 600;}

	}
}


</style>