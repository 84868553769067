<template>
  <div>
    <h5>CCO Participants</h5>
    <div class="OptionsCont">
      <v-autocomplete
        :items="ccoParticipants"
        v-model.trim="$v.ccoValue.$model"
        @change="$v.ccoValue.$touch()"
        @input="$v.ccoValue.$touch()"
        @blur="$v.ccoValue.$touch()"
        solo
        flat
        label="Choose CCO participants"
        color="white"
        multiple
        chips
        deletable-chips
      ></v-autocomplete>
    </div>
  </div>
</template>

<script>
import { formValidations } from "@/mixins/formValidations";
/* vuelidate mixin & validators */
import { validationMixin } from 'vuelidate'
import { helpers } from 'vuelidate/lib/validators';

const isOptional = (value) => !helpers.req(value) || value.indexOf('cool') >= 0

export default {
  name: 'participants',
	mixins: [validationMixin, formValidations],
  props: {
    ccoParticipants: {
      type: Array,
    },
  },
  validations: {
		ccoValue: {
			isOptional
		}
	}
}
</script>

<style lang="less" scoped>
h5 {
  font-weight: normal;
  font-size: 15px;
  color: #547fa9;
}

.OptionsCont {
  width: 100%;
  height: 50px;
}

@media (max-width: 640px) {
  h5 {
    font-size: 14px;
  }
}
</style>