<template>
<div class="ButtonsCont d-flex flex-column align-end">
	<div class="dropCont mt-3">
    <v-menu
      left
      z-index="3000"
      :offset-x="offset"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          rounded
          large
          depressed
          outlined
          class="btn"
          color="#003D6D"
        >
        	<v-icon class="mr-2">
        		mdi-chevron-down
        	</v-icon>
          Define Quotation
        </v-btn>
      </template>

      <div class="ListFh">
        <v-list class="listContent  ">

          <div class="pl-5 pr-5">
            <div 
            	@click="defineQuotation(item.content)" 
    	        v-for="(item, index) in items"
    	        :key="index"
    	        class="button d-flex justify-start align-center ">
            	{{ item.content }}
            </div>

            <div 
              @click="defineQuotation('Send proportional  and non-proportional quotation without slip')" 

              class="buttoNoBorder d-flex justify-start  align-center ">
              Send proportional  and non-proportional quotation without slip
            </div>
          </div>

          <div class="declineOffer">
            <div class="buttoNoBorder d-flex justify-start align-center pl-5 pr-5">
              Ask for more information
            </div>
          </div>
        </v-list>
      </div>
    </v-menu>
	</div>

	<v-btn 
		rounded
    large
    color="#003D6D"
    depressed
    class="btn mt-3"
    @click="$emit('sendToBound')"
	>
		Send to Bound
	</v-btn>
</div>
</template>
<script>
  export default {
    data: () => ({
      items: [
        { content: 'Send proportional quotation with slip' },
        { content: 'Send proportional quotation without slip' },
        { content: 'Send proportional  and non-proportional quotation with slip' },
        
      ],
      offset: true,
    }),
    methods:{
    	/*
				El metodo defineQuotation devuelve el tipo de quotation elegido
    	*/
    	defineQuotation(event)
    	{
    		//alert(event)
    	},
    }
  }
</script>
<style lang="less" scoped>
.ButtonsCont{
  width: 100%;
  height: auto;
  margin-top: 10px;
}
.ListFh{
  overflow: hidden !important;
  .listContent{
    width: 400px;
    height: 320px !important;

    //BOTONES
    .button{
      color: #003D6D;
      border-bottom: solid 1px;
      cursor: pointer;
      min-height: 60px;
    }
    .buttoNoBorder{
      color: #003D6D;
      cursor: pointer;
      height: 60px;
    }
    .declineOffer{
      width: 100%;
      height: 150px;
      
      .declineTitle{
        width: 100%;
        height: 50px;
        background:#EDF2F8;
        color: #003D6D;
      }
      .declineButton{
        width: 80%;
        margin-left: 10%;
        height: 45px;
        color: #003D6D;
        border-bottom:solid 1px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

.btn{
  width: 220px;
  color: white;
  text-transform: none;
  font-weight: 500;
  height: 40px !important;
  font-size: 15px;
}

.v-menu__content{
  position: fixed !important;
}
@media(max-width: 640px)
{
  .ListFh{
    width: 100%;
    height: 100vh;
    background:rgba(0,0,0,0.5);
    left: 0; top: 0;
    display: flex;
    justify-content: center;
    align-items:center;
    overflow: auto !important;

    .listContent{
      width: 90%;
      border-radius: 15px;
    }
  }
  /*
  MODIFICA REGLAS DE VUETIFY
  */
  .v-menu__content
  {
    max-width: 100% !important;
    left: 0 !important;
    top: 0 !important;
  }
}
</style>