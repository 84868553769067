<template>
<div class="Cont d-flex justify-start align-center flex-wrap align-content-center ">
	<!--TITULO-->
	<div class="TitleCont">
		<h5>
			CAT Rates
		</h5>
	</div>
	<div class="
		Table
		d-flex
		justify-end
		align-content-start
		flex-wrap
		">
		<!--LABELS-->
		<div class="Col LabelsCol">
			<div class="Row" />
			<div class="Row">
				Ponderated EQ Rate
			</div>
			<div class="Row">
				Ponderated Hidrometeorological Rate
			</div>
			<div class="Row">
				Ponderated Flood Rate
			</div>
			<div class="Row">
				Ponderated Other Rate
			</div>
			<div class="Row">
				<b>
					CAT Rate
				</b>
			</div>
		</div>

		<!--COLUMNA SIN NOMBRE-->
		<div class="Col">
			<div class="Row TitleCol" />
			<div class="Row">
				<v-text-field
					v-model="EQRate1"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="Hydro1"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="Flood1"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="Other1"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="CatRate"
					>
				</v-text-field>
			</div>
		</div>

		<!--CREDIT SURCHARGE-->
		<div class="Col">
			<div class="Row TitleCol">
				Credit / <br> Surcharge
			</div>
			<div class="Row">
				<v-text-field
					v-model="EQRate2"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="Hydro2"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="Flood2"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="Other2"
					>
				</v-text-field>
			</div>
			<div class="Row" />
		</div>

		<!--REASON-->
		<div class="Col">
			<div class="Row TitleCol">
				Reason
			</div>
			<div class="Row">
				<v-select
					v-model="EQRate2"
					>
				</v-select>
			</div>
			<div class="Row">
				<v-select
					v-model="Hydro2"
					>
				</v-select>
			</div>
			<div class="Row">
				<v-select
					v-model="Flood2"
					>
				</v-select>
			</div>
			<div class="Row">
				<v-select
					v-model="Other2"
					>
				</v-select>
			</div>
			<div class="Row" />
		</div>

		<!--FINAL RATE-->
		<div class="Col">
			<div class="Row TitleCol">
				Final Rate
			</div>
			<div class="Row">
				<v-text-field
					v-model="EQRate3"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="Hydro3"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="Flood3"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="Other3"
					>
				</v-text-field>
			</div>
			<div class="Row" />
		</div>

		<!--PREMIUM RATE-->
		<div class="Col">
			<div class="Row TitleCol">
				Premium Rate
			</div>
			<div class="Row">
				<v-text-field
					v-model="EQRate4"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="Hydro4"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="Flood4"
					>
				</v-text-field>
			</div>
			<div class="Row">
				<v-text-field
					v-model="Other4"
					>
				</v-text-field>
			</div>
			<div class="Row" />
		</div>
	</div>

	<!--SEPARADOR-->
	<div class="ExpansionLineTop mt-4 HideOnMovil"/>

</div>
</template>
<script>
export default{
	name:'CatRates',
	data(){
		return{
			EQRate1:null,
			Hydro1:null,
			Flood1:null,
			Other1:null,
			CatRate:null,

			EQRate2:null,
			Hydro2:null,
			Flood2:null,
			Other2:null,

			EQRate3:null,
			Hydro3:null,
			Flood3:null,
			Other3:null,

			EQRate4:null,
			Hydro4:null,
			Flood4:null,
			Other4:null
		}
	}
}
</script>
<style lang="less" scoped>
/*
Estilos compartidos por los componentes
del tarifador, controlan:
	- Clase .flex()
	- Titulo general
	- Contenedor general
*/
@import '~@/assets/style/Subscription/Rate.less';
.Cont{
	width: 100%;
	height: auto;
	padding:0 !important;
	/*
		Estilos únicos del componente
	*/
	.Table{
		width: 100%;
		height: 300px;
		
		.Col{
			.flex();
			width: 13%;
			height: 100%;
			justify-content: space-between;
			align-content:flex-start;
			flex-wrap: wrap;
			//LABELS
			.Row{
				.flex();
				width: 100%;
				height: 50px;
				text-align: right;
				justify-content: flex-end;
				padding-left: 7px;
			}

			.TitleCol{
				.flex();
				justify-content: flex-start;
				font-weight: 600 !important;
				text-align: left;
			}
		}
		.LabelsCol{
			width: 27%;
			.Row{
				padding-right: 7px;
				padding-left: 0px;
				color:#547FA9;
				b{
					color: inherit;
				}
			}
		}
	}
}
</style>