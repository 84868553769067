<template>
  <v-text-field
    ref="inputRef"
    :value="formattedValue"
    :prefix="prefix"
    :suffix="suffix"
    :error-messages="errorMessages"
    :placeholder="placeholder"
    :class="css"
    @blur="onBlur()"
  />
</template>

<script>
import { watch } from '@vue/composition-api';
import { useCurrencyInput } from 'vue-currency-input';

export default {
  name: 'CurrencyInput',
  props: {
    value: {
      type: Number | String,
      default: null,
    },
    options: {
      type: Object,
      default: () => {},
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: Function | Array,
      default: () => {},
    },
    css: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { inputRef, formattedValue, setOptions, setValue } = useCurrencyInput(
      props.options
    );

    watch(
      () => props.value,
      (value) => {
        // Vue 2: props.value
        setValue(value);
      }
    );

    watch(
      () => props.options,
      (options) => {
        setOptions(options);
      }
    );

    return { inputRef, formattedValue };
  },
  methods: {
    onBlur() {
      this.$emit('blur');
    },
    onChange() {
      this.$emit('change');
    },
  },
};
</script>