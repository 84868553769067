<template>
  <div class="ExpandContent" :disabled="loadingPanel">
    <v-form class="Form d-flex justify-start align-center flex-wrap">

      <!--CURRENCY-->
      <div class="InputContent">
        <v-select
          v-model.trim="$v.accountInformation.currency.$model"
          required
          label="Currency"
          :items="currencies"
          item-text="key"
          item-value="id"
          :hint="`${$v.accountInformation.currency.$model.region || 'Region'}, ${$v.accountInformation.currency.$model.description || 'Description'}`"
          return-object
          :loading="loadingCurrencies"
          :disabled="currencies.length === 0"
          @blur="setStateQuotation('currency', this)"
        >
        </v-select>
      </div>

      <!--TIV-->
      <div class="InputContent">
        <v-text-field
          label="TIV"
          >
        </v-text-field>
      </div>

      <!--INCEPTION DATE-->
      <div class="InputContent">
        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="quotation.inceptionDate" label="Inception Date" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="quotation.inceptionDate" @input="(menu2 = false), setStateQuotation('inceptionDate', this)"></v-date-picker>
        </v-menu>
      </div>

      <!--EXPIRY DATE-->
      <div class="InputContent">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="quotation.expiryDate" label="Expiry Date" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="quotation.expiryDate" @input="(menu = false), setStateQuotation('expiryDate', this)"></v-date-picker>
        </v-menu>
      </div>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { required, requiredIf, minLength } from 'vuelidate/lib/validators';
export default {
  name: 'ProportionalRates',
  data() {
    return {
      currencyItems: [],
      loadingPanel: false,
      loadingCurrencies: false,
      menu2: false,
      menu: false,
    };
  },
  async mounted() {
    /* set loadings (data) */
    const lpa = 'loadingPanel';
    const lcu = 'loadingCurrencies';
    /* loaders to true */
    this[lpa] = !this[lpa];
    this[lcu] = !this[lcu];
    /* obtención de catálogos */
    await this.getCatalogByName({ name: 'currencies' });

    /* loaders to false */
    this[lcu] = false;
    this[lpa] = false;
  },
  computed: {
    ...mapGetters(['accountInformation', 'currencies', 'quotation']),
  },
  methods: {
    ...mapActions(['getCatalogByName']),
    ...mapMutations(['setLoading', 'setStateQuotation']),
  },
  validations: {
    accountInformation: {
      insuredName: { required },
      currency: { required },
    },
  },
};
</script>
<style lang="less" scoped>
.flexCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.Form {
  width: 100%;
  height: 60px;
  .InputContent {
    width: 19%;
    margin-right: 1%;
    height: 100%;
    .flexCenter();
  }
}

@media (max-width: 640px) {
  .Form {
    height: auto;
    .InputContent {
      width: 100%;
    }
  }
}
</style>
