<template>
  <div v-if="subscription_id" class="StepperHeadComponent d-flex justify-center align-center mt-1">
    <!--PASOS SIGUIENTES-->
    <button class="StepContent d-flex justify-center align-center" :class="step1" @click="changueView(2)">
      <!--NUMERO DEL PASO-->
      <div class="StepNumber d-flex justify-center align-center">
        1
      </div>
      <!--NOMBRE DEL PASO-->
      <div class="StepText ml-2">
        Submission
      </div>
    </button>

    <button class="StepContent d-flex justify-center align-center ml-10 mr-10" :class="step2" @click="changueView(6)">
      <!--NUMERO DEL PASO-->
      <div class="StepNumber d-flex justify-center align-center">
        2
      </div>

      <!--NOMBRE DEL PASO-->
      <div class="StepText ml-2">
        Quotation
      </div>
    </button>

    <button class="StepContent d-flex justify-center align-center" :class="step3" @click="changueView(5)">
      <!--NUMERO DEL PASO-->
      <div class="StepNumber d-flex justify-center align-center">
        3
      </div>

      <!--NOMBRE DEL PASO-->
      <div class="StepText ml-2">
        Bound
      </div>
    </button>
  </div>
  <div v-else class="StepperHeadComponent d-flex justify-center align-center mt-1">
    <!--PASOS SIGUIENTES-->
    <button class="StepContent d-flex justify-center align-center" :class="step1" @click="changueView(1)">
      <!--NUMERO DEL PASO-->
      <div class="StepNumber d-flex justify-center align-center">
        1
      </div>
      <!--NOMBRE DEL PASO-->
      <div class="StepText ml-2">
        Submission
      </div>
    </button>

    <button class="StepContent d-flex justify-center align-center ml-10 mr-10" :class="step2" @click="changueView(3)">
      <!--NUMERO DEL PASO-->
      <div class="StepNumber d-flex justify-center align-center">
        2
      </div>

      <!--NOMBRE DEL PASO-->
      <div class="StepText ml-2">
        Quotation
      </div>
    </button>

    <button class="StepContent d-flex justify-center align-center" :class="step3" @click="changueView(5)">
      <!--NUMERO DEL PASO-->
      <div class="StepNumber d-flex justify-center align-center">
        3
      </div>

      <!--NOMBRE DEL PASO-->
      <div class="StepText ml-2">
        Bound
      </div>
    </button>
  </div>
</template>
<script>
import { stepper } from '@/mixins/subscription.js';
import { mapGetters } from 'vuex';

export default {
  mixins: [stepper],
  name: 'stepperSubscription',
  computed: {
    ...mapGetters(['subscription_id']),
  },
  methods: {
    changueView(path) {
      var paths = [
        '/subscription/submission',
        '/subscription/' + this.subscription_id + '/submission',
        '/subscription/quotation/proportional',
        '/subscription/quotation/non-proportional',
        '/subscription/bound',
        '/subscription/' + this.subscription_id + '/quotation/proportional',
      ];

      if (this.$route.path != paths[path - 1]) {
        if (path == 3 && this.$route.path != paths[0]) {
          this.$router.push(paths[path - 1]);
        }
        if (path == 1 || path == 2 || path == 6) {
          this.$router.push(paths[path - 1]);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import '~@/assets/style/Subscription/Stepper.less';
</style>
