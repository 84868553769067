<template>
  <div class="ExchangeCalcCont d-flex justify-center flex-wrap align-center">
    <!--TITULO DEL COMPONENTE-->
    <div class="TitleComponent d-flex justify-space-between align-center">
      <h5>Premium</h5>

      <!--BOTON TARIFAR-->
      <ButtonActiveModalRate />
    </div>

    <!--TABLA-->
    <div class="Table">
      <!--
		-------------------------------------
			PRIMER COLUMNA - LABELS
		-------------------------------------
		-->
      <div class="Col LabelsCol">
        <!--TITULO-->
        <div class="Row Title" />

        <!--CONTENIDO-->
        <div class="Row Label">Property Damage</div>
        <div class="Row Label">Bussiness Interruption</div>
        <div class="Row Label">Stocks</div>
        <div class="Row Total">Total</div>
      </div>

      <!--
		-------------------------------------
			SEGUNDA COLUMNA - RATE
		-------------------------------------
		-->
      <div class="Col">
        <!--TITULO-->
        <div class="Row Title">Rate</div>

        <!--CONTENIDO-->
        <div class="Row Content">
          <div class="Label">Property Damage</div>
          <div class="Input">
            <v-text-field
              type="number"
              v-model="premium.propertyDamageRate"
              suffix="%"
              @blur="calculeOriginal(), calculeUSD(), setStatePremium('propertyDamageRate'), checkTobeColumn('propertyDamageRate')"
              :error-messages="quotationValids('premium', 'propertyDamageRate')"
            />
          </div>
        </div>
        <div class="Row Content">
          <div class="Label">Bussiness Interruption</div>
          <div class="Input">
            <v-text-field
              type="number"
              v-model="premium.businessInterruptionRate"
              suffix="%"
              @blur="calculeOriginal(), calculeUSD(), setStatePremium('businessInterruptionRate'), checkTobeColumn('businessInterruptionRate')"
              :error-messages="quotationValids('premium', 'businessInterruptionRate')"
            />
          </div>
        </div>
        <div class="Row Content">
          <div class="Label">Stocks</div>
          <div class="Input">
            <v-text-field
              type="number"
              v-model="premium.stockRate"
              suffix="%"
              @blur="calculeOriginal(), calculeUSD(), setStatePremium('stockRate'), checkTobeColumn('stockRate')"
              :error-messages="quotationValids('premium', 'stockRate')"
            />
          </div>
        </div>
        <div class="Row Total HideOnMovil" />
      </div>

      <!--
		-------------------------------------
			TERCER COLUMNA - ORIGINAL INSURED
		-------------------------------------
		-->
      <div class="Col">
        <!--TITULO-->
        <div class="Row Title">Original Insured</div>

        <!--CONTENIDO-->
        <div class="Row Content">
          <div class="Label">Property Damage</div>
          <div class="Input">
            <currency-input :value="premium.propertyDamage" :options="currencyOptions" @change="value = $event" :suffix="quotation.currency" />
            <!-- <v-text-field
              type="number"
              v-model="premium.propertyDamage"
              prefix="$"
            /> -->
          </div>
        </div>
        <div class="Row Content">
          <div class="Label">Bussiness Interruption</div>
          <div class="Input">
            <currency-input :value="premium.businessInterruption" :options="currencyOptions" @change="value = $event" :suffix="quotation.currency" />
            <!-- <v-text-field
              type="number"
              v-model="premium.businessInterruption"
              prefix="$"
            /> -->
          </div>
        </div>
        <div class="Row Content">
          <div class="Label">Stocks</div>
          <div class="Input">
            <currency-input :value="premium.stock" :options="currencyOptions" @change="value = $event" :suffix="quotation.currency" />
            <!-- <v-text-field type="number" v-model="premium.stocks" prefix="$" /> -->
          </div>
        </div>

        <!--TOTAL-->
        <div class="Row TotalContent">
          <div class="Label">Total</div>
          <div class="TotalText">{{ premium.totalInsured }}</div>
        </div>
      </div>

      <!--
		-------------------------------------
			CUARTA COLUMNA - USD
		-------------------------------------
		-->
      <div class="Col">
        <!--TITULO-->
        <div class="Row Title">USD</div>

        <!--CONTENIDO-->
        <div class="Row Content">
          <div class="Label">Property Damage</div>
          <div class="Input">
            <currency-input :value="premium.propertyDamageUSD" :options="currencyOptions" @change="premium.propertyDamageUSD = $event" suffix="USD" />
            <!-- <v-text-field
              type="number"
              v-model="premium.propertyDamageUSD"
              prefix="$"
            /> -->
          </div>
        </div>
        <div class="Row Content">
          <div class="Label">Bussiness Interruption</div>
          <div class="Input">
            <currency-input
              :value="premium.businessInterruptionUSD"
              :options="currencyOptions"
              @change="premium.businessInterruptionUSD = $event"
              suffix="USD"
            />
            <!-- <v-text-field
              type="number"
              v-model="premium.businessInterruptionUSD"
              prefix="$"
            /> -->
          </div>
        </div>
        <div class="Row Content">
          <div class="Label">Stocks</div>
          <div class="Input">
            <currency-input :value="premium.stockUSD" :options="currencyOptions" @change="premium.stockUSD = $event" suffix="USD" />
            <!-- <v-text-field
              type="number"
              v-model="premium.stocksUSD"
              prefix="$"
            /> -->
          </div>
        </div>

        <!--TOTAL-->
        <div class="Row TotalContent">
          <div class="Label">Total</div>
          <div class="TotalText">{{ premium.totalUSD }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
/* validations */
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { DigitsAndDecimals, Percentage } from '@/constants/validations';
import { formValidations } from '@/mixins/formValidations';
/* components */
import CurrencyInput from '@/components/CurrencyInput/CurrencyInput.vue';
import ButtonActiveModalRate from '@/components/Rate/ButtonActiveModalRate.vue';

export default {
  name: 'ExchangeCalculator',
  mixins: [formValidations, validationMixin],
  components: {
    CurrencyInput,
    ButtonActiveModalRate,
  },
  data() {
    return {
      currencyOptions: {
        currency: 'MXN',
        currencyDisplay: 'narrowSymbol',
        locale: 'en-US',
      },
    };
  },
  computed: {
    ...mapGetters(['quotation', 'tiv', 'premium']),
  },
  watch: {
    '$store.state.quotation.exchangeRate': function() {
      if (this.premium.propertyDamageRate || this.premium.businessInterruptionRate || this.premium.stockRate) {
        this.calculeUSD();
        this.calculeOriginal();
      }
    },
    '$store.state.tiv.propertyDamage': function() {
      if (this.premium.propertyDamageRate) {
        this.calculeUSD();
        this.calculeOriginal();
      }
    },
    '$store.state.tiv.businessInterruption': function() {
      if (this.premium.businessInterruptionRate) {
        this.calculeUSD();
        this.calculeOriginal();
      }
    },
    '$store.state.tiv.stock': function() {
      if (this.premium.stockRate) {
        this.calculeUSD();
        this.calculeOriginal();
      }
    },
  },
  validations: {
    premium: {
      propertyDamage: { required, DigitsAndDecimals },
      businessInterruption: { required, DigitsAndDecimals },
      stock: { required, DigitsAndDecimals },
      propertyDamageRate: { required, DigitsAndDecimals, Percentage },
      businessInterruptionRate: { required, DigitsAndDecimals, Percentage },
      stockRate: { required, DigitsAndDecimals, Percentage },
      totalInsured: { required, DigitsAndDecimals },
      totalUSD: { required, DigitsAndDecimals },
      porcentaje: { required, DigitsAndDecimals },
      propertyDamageUSD: { required, DigitsAndDecimals },
      businessInterruptionUSD: { required, DigitsAndDecimals },
      stockUSD: { required, DigitsAndDecimals },
    },
  },
  methods: {
    ...mapMutations(['setStatePremium']),
    ...mapActions(['saveQuotationColumn']),
    calculeOriginal() {
      this.premium.propertyDamage = (this.tiv.propertyDamage * this.premium.propertyDamageRate) / 1000;
      this.premium.businessInterruption = (this.tiv.businessInterruption * this.premium.businessInterruptionRate) / 1000;
      this.premium.stock = (this.tiv.stock * this.premium.stockRate) / 1000;
      this.sumData();
    },
    calculeUSD() {
      this.premium.propertyDamageUSD = (this.tiv.propertyDamageUSD * this.premium.propertyDamageRate) / 1000;
      this.premium.businessInterruptionUSD = (this.tiv.businessInterruptionUSD * this.premium.propertyDamageRate) / 1000;
      this.premium.stockUSD = (this.tiv.stockUSD * this.premium.propertyDamageRate) / 1000;
      this.sumData();
    },
    sumData() {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      this.premium.totalInsured =
        (!this.premium.propertyDamage ? 0 : parseFloat(this.tiv.propertyDamage)) +
        (!this.premium.businessInterruption ? 0 : parseFloat(this.tiv.businessInterruption)) +
        (!this.premium.stock ? 0 : parseFloat(this.tiv.stock));

      this.premium.totalInsured = formatter.format(this.premium.totalInsured);

      this.premium.totalUSD =
        (!this.premium.propertyDamageUSD ? 0 : parseFloat(this.tiv.propertyDamageUSD)) +
        (!this.premium.businessInterruptionUSD ? 0 : parseFloat(this.tiv.businessInterruptionUSD)) +
        (!this.premium.stockUSD ? 0 : parseFloat(this.tiv.stockUSD));

      this.premium.totalUSD = formatter.format(this.premium.totalUSD);
    },
    async checkTobeColumn(column) {
      this.$v.premium[column].$touch();
      console.log(this.$v.premium[column].$invalid, this.$v.premium[column].$error);
      if (this.$v.premium[column].$invalid || this.$v.premium[column].$error) return;
      await this.saveQuotationColumn({
        table: 'premium',
        parent: 'premium',
        column,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ExchangeCalcCont {
  width: 100%;
  height: auto;

  //TITULO DEL COMPONENTE
  .TitleComponent {
    width: 100%;
    height: 60px;
    margin-top: 15px;
    h5 {
      font-size: 20px;
      color: black;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  //TABLA
  .Table {
    .flexCenter();
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    /*
		LABELS EN DESKTOP
		*/
    .Col {
      margin-right: 10px;
      .flexCenter();
      width: 18%;
      height: auto;
      align-items: flex-start;
      flex-wrap: wrap;

      .Row {
        .flexCenter();
        width: 100%;
        height: 45px;

        //INPUTS
        .Input {
          width: 100%;
        }
        //LABEL MOVIL
        .Label {
          display: none;
        }
      }

      //TITULO DE LA COLUMNA
      .Title {
        height: 40px;
        font-weight: bold;
        justify-content: flex-start;
      }
      //LABEL
      .Label {
        padding-right: 10px;
        color: #547fa9;
        justify-content: flex-end;
        text-align: right;
        font-size: 15px;
      }
      //TOTAL
      .Total {
        font-weight: 600;
        padding-right: 10px;
        font-size: 15px;
        color: #547fa9;
        justify-content: flex-end;
        margin-top: 15px;
      }

      //TOTAL TEXT
      .TotalContent {
        font-weight: 600;
        margin-top: 15px;
      }

      //CONTENIDO
      .Content {
        justify-content: flex-start;
      }
    }
  }
}

@media (max-width: 650px) {
  .ExchangeCalcCont {
    .Table {
      .Col {
        margin-right: 0px;
        width: 100%;
        .Row {
          .Label {
            display: flex !important;
            width: 45%;
          }
          //INPUTS
          .Input {
            width: 55%;
          }
        }
        .Label {
          font-size: 12px;
        }
        .TotalContent {
          justify-content: flex-end;
          .TotalText {
            width: 55%;
            font-size: 16px;
          }
        }
      }
    }
    //OCULTAR LABELS
    .LabelsCol {
      display: none !important;
    }
  }
}
</style>
