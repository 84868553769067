<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      class="drawer"
      floating
      :mini-variant.sync="mini"
      :temporaly="temporary"
      :permanent="permanent"
      fixed
      >
      <v-list-item 
        class="px-2"
        v-if="!isMobile" 
        >
        <v-list-item-avatar>
          <v-btn icon >
            <v-icon color="#003D6D">
              mdi-menu
            </v-icon>
          </v-btn>
        </v-list-item-avatar>

        <v-list-item-title >
          
        </v-list-item-title>

        <v-btn
          icon
          @click="ToggleMenu"
        > 
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      
      <v-list  class="List" dense>

        <div class="MiddleLinks"> 
          <v-list-item
            class="LinkMenu"
            @click.stop="this.mini = false"
            v-for="item in items"
            :key="item.title"
            :to="item.route"
            > 
            <div>
              <v-list-item-icon >
                <v-icon color="#003D6D">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
            </div>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>



        <!--CERRAR SESIÓN-->
        <v-list-item 
          @click.native="logOut"
          class="LinkMenu"
          > 
          <div>
            <v-list-item-icon class="LinkIcon">
              <v-icon color="#003D6D">
               mdi-exit-to-app
              </v-icon>
            </v-list-item-icon>
          </div>
          <v-list-item-content>
            <v-list-item-title>
             Close
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        
      </v-list>
    </v-navigation-drawer>

    <!--MENU MOVIL-->
    <div 
      v-if="isMobile" 
      class="
        MenuMovil
        d-flex
        justify-space-between
        align-items-center
        px-2
      "
      >
      <!--BOTON QUE ACTIVA EL MENU-->
      <v-btn 
        @click.stop="drawer = !drawer"
        icon
        >
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>

      <!--TITULO DE LA PÁGINA-->
      <div class="TitlePage">
        Accounts
      </div>
      <!--BUSQUEDA Y NOTIFICACIONES-->
      <div 
      class="
        ActionsButtons
        d-flex
        justify-space-between
        align-center
        ">
        <!--BUSCADOR-->
        <Searcher />
        <!--NOTIFICACIONES-->
        <Notifications />
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.drawer{
  background: white;
  box-shadow: 4px 4px 6px rgba(10, 63, 102, 0.15);
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;

  .List{
    height: calc(100% - 60px);
    .MiddleLinks{
      height: 77%;  
      display: flex;
      justify-content: flex-start;
      align-items: center; 
      flex-wrap: wrap;
    }
  }
}
.LinkMenu{
  height: 54px;
  cursor: pointer;
}
.MenuMovil{
  width: 100%;
  height: 60px;
  background: white;
  align-items:center;
  align-content:center;

  //TITULO DE LA PÁGINA
  .TitlePage{
    width: calc(95% - 120px);
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items:center;
    font-weight: 600;
    font-size: 23px;
  }
  //ICONOS
  .ActionsButtons{
    width: 80px;
    height: 100%;
  }
}



</style>
<script>
import { mapActions } from 'vuex'
import Notifications from './Notifications.vue'
import Searcher from '@/components/Filters/Searcher'
export default {
  name: 'MenuGeneral',
  components:{
    Notifications,
    Searcher
  },
  methods: {
    ...mapActions(['logOut']),
    showDropMenu(){
      this.ShowDropMenu = !this.ShowDropMenu;
    },
    ToggleMenu(){
      this.mini = !this.mini;
    },

  },
  data(){
    return{
        items: [
          { 
            title: "User's Home", 
            icon: 'mdi-home-outline' ,
            route:'/dashboard'
          },
          { 
            title: 'Accounts', 
            icon: 'mdi-pencil-outline',
            route:'/subscription'
          },
          { 
            title: 'Claims', 
            icon: 'mdi-alert-outline',
            route:'' 
          },
          { 
            title: 'Portfolio and accounting', 
            icon: 'mdi-folder-outline',
            route:'' 
          },
          { 
            title: 'Facultative', 
            icon: 'mdi-checkbox-multiple-blank-outline',
            route:'' 
          },
          { 
            title: 'Results and reports', 
            icon: 'mdi-pulse',
            route:'' 
          },
          { 
            title: 'Create SLU user', 
            icon: 'mdi-account-outline',
            route:'/create/user' 
          },
          { 
            title: 'SLU Users', 
            icon: 'mdi-account-outline',
            route:'/users' 
          }
        ],
      drawer: false,
      mini: true,
      temporary:false //

    }
  },
  mounted(){
    if (this.isMobile) {
      this.drawer = false;
      this.mini= false;
      this.temporary = true;
    }
  },
  computed:{
    isMobile(){
      return window.innerWidth > 600 ? false : true
    },
    permanent(){
      return window.innerWidth > 600 ? true : false
    },

  }
}
</script>
