<template>
  <div class="ExchangeCalcCont d-flex justify-center flex-wrap align-center">
    <!--TITULO-->
    <div class="TitleComponent d-flex justify-start align-center">
      <h5>To be defined</h5>
    </div>

    <!--PARTE IZQUIERDA-->
    <div class="ExchangeCont d-flex justify-end align-content-start flex-wrap">
      <!--TITULO-->
      <div
        class="
          TitleCont
          d-flex
          justify-center
          align-content-center align-center
        "
      >
        <h5>Original currency</h5>
      </div>

      <!--LINEA DIVISORIA-->
      <div class="ExpansionLineTop"></div>

      <!--INPUTS-->
      <div class="InputContent d-flex justify-end align-center mt-2">
        <span class="LabelInput"> Limited Insured: </span>

        <div class="InputContainer">
          <!-- <v-text-field
            class="ml-3"
            v-model="$v.toBeDefined.limitedInsured.$model"
            :placeholder="limitedInsured"
            :suffix="quotation.currency"
            @blur="$v.toBeDefined.limitedInsured.$touch()"
            @input="$v.toBeDefined.limitedInsured.$touch()"
            :error-messages="requiredToBeDefined('limitedInsured')"
          ></v-text-field> -->
          <currency-input
            class="ml-3"
            v-model="$v.toBeDefined.limitedInsured.$model"
            :placeholder="limitedInsured"
            :suffix="quotation.currency"
            :options="currencyOptions"
            @blur="
              $v.toBeDefined.limitedInsured.$touch();
              setStateTobe('limitedInsured', $v.toBeDefined.limitedInsured.$model);
              checkTobeColumn('limitedInsured');
            "
            @input="$v.toBeDefined.limitedInsured.$touch()"
            :errorMessages="requiredToBeDefined('limitedInsured')"
            css="ml-3"
          ></currency-input>
        </div>
      </div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> SLU Share </span>

        <div class="InputContainer">
          <v-text-field
            class="ml-3"
            v-model="$v.toBeDefined.sluShare.$model"
            :placeholder="sluShare"
            suffix="%"
            @blur="
              $v.toBeDefined.sluShare.$touch();
              setStateTobe('sluShare', $v.toBeDefined.sluShare.$model);
              checkTobeColumn('sluShare');
            "
            @input="$v.toBeDefined.sluShare.$touch()"
            :error-messages="quotationValids('toBeDefined', 'sluShare')"
          ></v-text-field>
        </div>
      </div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> Limit SLU </span>

        <div class="InputContainer">
          <v-text-field
            class="ml-3"
            v-model="originalLimitSlu"
            :placeholder="limitSlu"
            :suffix="quotation.currency"
            readonly
            :error-messages="requiredToBeDefined('limitSlu')"
          ></v-text-field>
        </div>
      </div>

      <div class="InputContent d-flex justify-end align-center">
        <span class="LabelInput"> Premium SLU </span>

        <div class="InputContainer">
          <v-text-field
            class="ml-3"
            v-model="originalPremiumSlu"
            :placeholder="premiumSlu"
            :suffix="quotation.currency"
            readonly
            :error-messages="requiredToBeDefined('premiumSlu')"
          ></v-text-field>
        </div>
      </div>
    </div>

    <!--PARTE DERECHA-->
    <div class="ExchangeCont d-flex justify-start align-content-start flex-wrap">
      <!--TITULO-->
      <div
        class="
          TitleCont
          d-flex
          justify-center
          align-content-center align-center
        "
      >
        <h5>USD</h5>
      </div>

      <!--LINEA DIVISORIA-->
      <div class="ExpansionLineTop"></div>

      <!--INPUTS-->
      <div class="InputContent d-flex justify-start align-center mt-2">
        <span class="LabelInput ShowFlexOnMovil"> Limited insured: </span>

        <div class="InputContainer inputRight">
          <v-text-field
            class="ml-3"
            v-model="usdLimitedInsured"
            :placeholder="limitedInsured"
            suffix="USD"
            readonly
            :error-messages="requiredToBeDefined('limitedInsuredUSD')"
          ></v-text-field>
        </div>
      </div>

      <div class="InputContent d-flex justify-start align-center">
        <span class="LabelInput ShowFlexOnMovil"> SLU Share: </span>

        <div class="InputContainer inputRight">
          <v-text-field
            class="ml-3"
            v-model="$v.toBeDefined.sluShare.$model"
            :placeholder="sluShare"
            suffix="%"
            readonly
            @blur="$v.toBeDefined.sluShare.$touch()"
            @input="$v.toBeDefined.sluShare.$touch()"
            :error-messages="quotationValids('toBeDefined', 'sluShare')"
          ></v-text-field>
        </div>
      </div>

      <div class="InputContent d-flex justify-start align-center">
        <span class="LabelInput ShowFlexOnMovil"> Limit SLU: </span>

        <div class="InputContainer inputRight">
          <v-text-field
            class="ml-3"
            v-model="usdLimitSlu"
            :placeholder="limitSlu"
            suffix="USD"
            readonly
            :error-messages="requiredToBeDefined('limitSluUSD')"
          ></v-text-field>
        </div>
      </div>

      <div class="InputContent d-flex justify-start align-center">
        <span class="LabelInput ShowFlexOnMovil"> Premium SLU: </span>

        <div class="InputContainer inputRight">
          <v-text-field
            class="ml-3"
            v-model="usdPremiumSlu"
            :placeholder="premiumSlu"
            suffix="USD"
            readonly
            :error-messages="requiredToBeDefined('premiumSluUSD')"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
/* vuelidate mixin & validations */
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
/* project validations (in some cases depends on vuelidate) */
import { formValidations } from '@/mixins/formValidations';
import { DigitsAndDecimals, CurrencyDigits, Percentage } from '@/constants/validations';
/* currency component */
import CurrencyInput from '@/components/CurrencyInput/CurrencyInput.vue';

export default {
  name: 'ToBe',
  components: { CurrencyInput },
  mixins: [formValidations, validationMixin],
  data() {
    return {
      limitedInsured: '100,000,00.00',
      sluShare: '80',
      limitSlu: '100,000,00.00',
      premiumSlu: '700,000,00.00',
      currentCurrency: 'MXN',
      usdCurrency: 'USD',
      totalPremiumUSD: 22000,
      currencyOptions: {
        currency: 'MXN',
        currencyDisplay: 'narrowSymbol',
        locale: 'en-US',
      },
      roe: 0.2,
      value: 1234,
    };
  },
  computed: {
    ...mapGetters(['toBeDefined', 'quotation']),
    usdLimitedInsured: {
      get() {
        const op = this.$v.toBeDefined.limitedInsured.$model / this.quotation.exchangeRate || 0;

        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        return formatter.format(op);
      },
    },
    usdLimitSlu: {
      get() {
        const fracc = this.$v.toBeDefined.sluShare.$model / 100;
        const curr = Number(this.usdLimitedInsured.replace(/[^0-9.-]+/g, ''));
        const op = curr * fracc || 0;

        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        return formatter.format(op);
      },
    },
    originalLimitSlu: {
      get() {
        const fracc = this.$v.toBeDefined.sluShare.$model / 100;
        const curr = Number(this.usdLimitedInsured.replace(/[^0-9.-]+/g, ''));
        const op = (curr * fracc) / this.quotation.exchangeRate || 0;

        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        return formatter.format(op);
      },
    },
    usdPremiumSlu: {
      get() {
        const fracc = this.$v.toBeDefined.sluShare.$model / 100;
        const op = this.totalPremiumUSD * fracc || 0;

        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        return formatter.format(op);
      },
    },
    originalPremiumSlu: {
      get() {
        const fracc = this.$v.toBeDefined.sluShare.$model / 100;
        const op = (this.totalPremiumUSD * fracc) / this.quotation.exchangeRate || 0;

        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        return formatter.format(op);
      },
    },
  },
  watch: {
    originalPremiumSlu(value) {
      this.$v.toBeDefined.premiumSlu.$model = value;
      this.$v.toBeDefined.premiumSlu.$touch();
    },
    usdPremiumSlu(value) {
      this.$v.toBeDefined.premiumSluUSD.$model = value;
      this.$v.toBeDefined.premiumSluUSD.$touch();
    },
    originalLimitSlu(value) {
      this.$v.toBeDefined.limitSlu.$model = value;
      this.$v.toBeDefined.limitSlu.$touch();
    },
    usdLimitSlu(value) {
      this.$v.toBeDefined.limitSluUSD.$model = value;
      this.$v.toBeDefined.limitSluUSD.$touch();
    },
    usdLimitedInsured(value) {
      this.$v.toBeDefined.limitedInsuredUSD.$model = value;
      this.$v.toBeDefined.limitedInsuredUSD.$touch();
    },
  },
  validations: {
    toBeDefined: {
      limitSluUSD: { required, CurrencyDigits },
      premiumSluUSD: { required, CurrencyDigits },
      limitedInsuredUSD: { required, CurrencyDigits },
      limitSlu: { required, CurrencyDigits },
      sluShare: { required, DigitsAndDecimals, Percentage },
      premiumSlu: { required, CurrencyDigits },
      limitedInsured: { required },
    },
  },
  methods: {
    ...mapMutations(['setStateTobe']),
    ...mapActions(['saveQuotationColumn', 'createToBeDefined']),
    async submitForm() {
      try {
        this.$v.$touch();

        const isValid = !this.$v.$invalid;

        console.log(isValid, this.$v.toBeDefined.limitedInsured.$model, this.$v.toBeDefined.sluShare.$model);

        if (!isValid) return;

        const toBe = {
          limitSluUsd: this.$v.toBeDefined.limitSluUSD.$model,
          premiumSluUsd: this.$v.toBeDefined.premiumSluUSD.$model,
          limitedInsuredUsd: this.$v.toBeDefined.limitedInsuredUSD.$model,
          limitSlu: this.$v.toBeDefined.limitSlu.$model,
          sluShare: this.$v.toBeDefined.sluShare.$model,
          premiumSlu: this.$v.toBeDefined.premiumSlu.$model,
          limitedInsured: this.$v.toBeDefined.limitedInsured.$model.toString(),
        };

        console.log(toBe);

        await this.createToBeDefined(toBe);
      } catch (e) {
        console.error(e);
      }
    },
    async checkTobeColumn(column) {
      this.$v.toBeDefined[column].$touch();
      console.log(this.$v.toBeDefined[column].$invalid, this.$v.toBeDefined[column].$error);
      if (this.$v.toBeDefined[column].$invalid) return;
      await this.saveQuotationColumn({
        table: 'tobe',
        parent: 'toBeDefined',
        column,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ExchangeCalcCont {
  width: 100%;
  height: auto;

  //TITULO DEL COMPONENTE
  .TitleComponent {
    width: 100%;
    height: 50px;
    margin-top: 15px;
    h5 {
      font-size: 20px;
      color: black;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .ExchangeCont {
    width: 35%;
    height: auto;
    padding-bottom: 40px;
    //TITULO
    .TitleCont {
      width: 100%;
      height: 60px;
      h5 {
        font-size: 19px;
        text-transform: capitalize;
        color: black;
        font-weight: 600;
      }
    }

    //INPUTS
    .InputContent {
      width: 100%;
      height: 50px;

      //LABEL
      .LabelInput {
        color: #547fa9;
        width: 40%;
        text-align: right;
        font-size: 16px;
      }

      .InputContainer {
        width: 40%;
      }
      .inputRight {
        margin-left: 5%;
      }
      .MiniInputContainer {
        width: 20%;
      }
    }

    .Total {
      font-size: 19px;
    }
  }

  //DIVISION VERTICAL
  .VerticalDiver {
    width: 1px;
    height: 490px;
    background: black;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
}

@media (max-width: 640px) {
  .ExchangeCalcCont {
    .ExchangeCont {
      width: 100%;
      padding: 0 !important;
      //TITULO
      .TitleCont {
        justify-content: flex-start !important;
        h5 {
          font-size: 15px;
        }
      }

      //INPUTS
      .InputContent {
        .LabelInput {
          font-size: 14px;
          height: 55%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          width: 38%;
        }

        .InputContainer {
          width: 55%;
        }
      }

      //TOTAL
      .Total {
        font-size: 14px;
      }
    }
  }
}
</style>
