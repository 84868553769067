var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ExchangeCalcCont d-flex justify-center flex-wrap align-center"},[_vm._m(0),_c('div',{staticClass:"ExchangeCont"},[_c('div',{staticClass:"TableCont"},[_c('div',{staticClass:"LargeColumn"},[_vm._m(1),_c('div',{staticClass:"InputLine"},[_c('div',{staticClass:"inputLabel"},[_vm._v("Property Damage")]),_c('div',{staticClass:"inputCont"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":_vm.quotation.currency},on:{"blur":function($event){_vm.calculeUSD();
                _vm.setStateTIV('propertyDamage', this);
                _vm.checkInsurableColumn('propertyDamage');}},model:{value:(_vm.tiv.propertyDamage),callback:function ($$v) {_vm.$set(_vm.tiv, "propertyDamage", $$v)},expression:"tiv.propertyDamage"}})],1)]),_c('div',{staticClass:"InputLine"},[_c('div',{staticClass:"inputLabel"},[_vm._v("Business Interruption")]),_c('div',{staticClass:"inputCont"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":_vm.quotation.currency},on:{"blur":function($event){_vm.calculeUSD();
                _vm.setStateTIV('businessInterruption', this);
                _vm.checkInsurableColumn('businessInterruption');}},model:{value:(_vm.tiv.businessInterruption),callback:function ($$v) {_vm.$set(_vm.tiv, "businessInterruption", $$v)},expression:"tiv.businessInterruption"}})],1)]),_c('div',{staticClass:"Stocks"},[_c('div',{staticClass:"inputLabel"},[_vm._v("Stocks")]),_c('div',{staticClass:"inputCont"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"suffix":_vm.quotation.currency},on:{"blur":function($event){_vm.calculeUSD();
                _vm.setStateTIV('stock', this);
                _vm.checkInsurableColumn('stock');}},model:{value:(_vm.tiv.stock),callback:function ($$v) {_vm.$set(_vm.tiv, "stock", $$v)},expression:"tiv.stock"}})],1),_c('div',{staticClass:"Percentaje"},[_c('v-text-field',{attrs:{"type":"number","suffix":"%","error-messages":_vm.quotationValids('tiv', 'porcentaje')},on:{"blur":function($event){_vm.setStateTIV('porcentaje', this);
                _vm.checkInsurableColumn('porcentaje');}},model:{value:(_vm.tiv.porcentaje),callback:function ($$v) {_vm.$set(_vm.tiv, "porcentaje", $$v)},expression:"tiv.porcentaje"}})],1)]),_c('div',{staticClass:"Porcentaje ShowFlexOnMovil"},[_c('div',{staticClass:"PorcentajeMovil"},[_c('v-text-field',{attrs:{"type":"number","suffix":"%","error-messages":_vm.quotationValids('tiv', 'porcentaje')},on:{"blur":function($event){_vm.setStateTIV('porcentaje', this);
                _vm.checkInsurableColumn('porcentaje');}},model:{value:(_vm.tiv.porcentaje),callback:function ($$v) {_vm.$set(_vm.tiv, "porcentaje", $$v)},expression:"tiv.porcentaje"}})],1)]),_c('div',{staticClass:"Total"},[_c('div',{staticClass:"TotalLabel"},[_vm._v("Total")]),_c('div',{staticClass:"TotalData"},[_vm._v(_vm._s(_vm.tiv.total))])])]),_vm._m(2),_c('div',{staticClass:"NormalColumn"},[_c('div',{staticClass:"titleCont"},[_vm._v("USD")]),_c('div',{staticClass:"InputLine"},[_c('div',{staticClass:"inputLabel ShowFlexOnMovil"},[_vm._v("Property Demage")]),_c('div',{staticClass:"inputCont"},[_c('currency-input',{attrs:{"value":_vm.tiv.propertyDamageUSD,"options":_vm.currencyOptions,"suffix":"USD"},on:{"blur":function($event){_vm.calculeUSD();
                _vm.setStateTIV('propertyDamageUSD', this);},"change":function($event){_vm.value = $event;
                _vm.setStateTIV('propertyDamageUSD', $event);
                _vm.checkInsurableColumn('propertyDamageUSD');}}})],1)]),_c('div',{staticClass:"InputLine"},[_c('div',{staticClass:"inputLabel ShowFlexOnMovil"},[_vm._v("Business interruption")]),_c('div',{staticClass:"inputCont"},[_c('currency-input',{attrs:{"value":_vm.tiv.businessInterruptionUSD,"options":_vm.currencyOptions,"suffix":"USD"},on:{"blur":function($event){_vm.calculeUSD();
                _vm.setStateTIV('businessInterruptionUSD', this);},"change":function($event){_vm.value = $event;
                _vm.setStateTIV('businessInterruptionUSD', $event);
                _vm.checkInsurableColumn('businessInterruptionUSD');}}})],1)]),_c('div',{staticClass:"InputLine"},[_c('div',{staticClass:"inputLabel ShowFlexOnMovil"},[_vm._v("Stocks")]),_c('div',{staticClass:"inputCont"},[_c('currency-input',{attrs:{"value":_vm.tiv.stockUSD,"options":_vm.currencyOptions,"suffix":"USD"},on:{"blur":function($event){_vm.calculeUSD();
                _vm.setStateTIV('stockUSD', this);},"change":function($event){_vm.value = $event;
                _vm.setStateTIV('stockUSD', $event);
                _vm.checkInsurableColumn('stockUSD');}}})],1)]),_c('div',{staticClass:"Total"})])])]),_c('div',{staticClass:"ExpansionLineTop mt-3 HideOnMovil"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TitleComponent d-flex justify-start align-center"},[_c('h5',[_vm._v("Total insurable value")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"titleCont"},[_c('div',{staticClass:"title"},[_vm._v("Original Currency")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Middle HideOnMovil"},[_c('div',{staticClass:"MiddleCont"})])}]

export { render, staticRenderFns }